import { useState } from "react";
import "./FormInput.css"

const FormInput=(props)=>{
    const [focused,setfocused]=useState(false);
    const{label,errorMessage, onChange,id, ...inputProps }=props;
    const handleFocus=(e)=>{
        setfocused(true);
    };
 
    return(
        <div className="formInput">
            <label>{label}</label>
            <input className="input" {...inputProps}  onChange={onChange} onBlur={handleFocus} 
            onFocus={()=>
                inputProps.name==="confirmPassword" && setfocused(true)}
            focused={focused.toString()} />
            <span className="error">{errorMessage}</span>
        </div>
    )
}
export default FormInput;