import {useState } from 'react';
import './RegForm.css';
import FormInput from './FormInput';
import Header from '../Header/Header';

const RegForm=()=>{
  const[values , setValues]=useState(
    {
      username:"", email:"",birthdate:"" , password:"",
      confirmPassword:"" ,
    }
  );
  const inputs=[
    {
      id:1,
      name:"username",
      type:"text",
      placeholder:"Username",
      errorMessage:"Username be with 3-16 character",
      label:"Username",
      pattern:'^[a-zA-Z0-9]{4,15}$',
      required:true,
    },
    {
      id:2,
      name:"email",
      type:"email",
      placeholder:"Email",
      errorMessage:"It should be a valid email address",
      label:"Email",
      required:true,
    },
    
    {
      id:3,
      name:"birthday",
      type:"date",
      placeholder:"Birthday",
      errorMessage:"",
      label:"Birthday",
      required:true,
    },
    {
      id:4,
      name:"password",
      type:"password",
      placeholder:"Password",
      errorMessage:"Password must be 7 characters",
      pattern:'^(?=.[0-9])(?=.[!@#$%^&*])[0-9a-zA-Z!@#$%^&*0-9]{8,20}$',
      label:"Password",
      required:true,
    },
    {
      id:5,
      name:"confirmPassword",
      type:"password",
      placeholder:"Confirm Password",
      errorMessage:"Password do not match",
      label:"Confirm Password",
      pattern:values.password,
      required:true,
    },

  ];
  
  console.log("re-rendered")
  const handleSubmit=(e)=>{
    e.preventDefault();
  };
  const onChange=(e)=>{
    setValues({...values,[e.target.name] : e.target.value});
  };
  console.log(values);
 
 
  return (
  <section className='FormPage'>
    <Header/>
    <div className='formContainer'>
    <form onSubmit={handleSubmit}>
      <h1 className='formTitle'>REGSTRATION</h1>
      {inputs.map((input)=>(
      <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange}/>
      ))}
      <div className='contain'>
       <label >Upload File</label>
      <input type='file' name="file" id="uploadbtn" placeholder='Upload File'/>
      </div>
     
      <button className='formBtn'>Submit</button>

    </form>
  </div>
  </section>
  );
};

export default RegForm;